import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.webp"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.webp"

import styles from "./styles.module.scss"
import classNames from "classnames"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Винотека «Первый нос»"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Винотека «Первый нос»"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img src={DesktopMediaMainBanner} alt="Главный баннер" loading="lazy" />
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Как дилетанты запустили сеть из 14 заведений в Москве. Кейс{" "}
				<span className={styles.quote}>«Первый нос»</span>
			</>
		),
	},
	heading: (
		<>
			Как из небольшой <wbr /> кофейни вырасти <wbr /> в сеть винотек <wbr /> и
			баров
		</>
	),
	textarea: (
		<p className={styles.text}>
			Героинь нашей истории называли дилетантами — профессионалы пророчили им
			провал. Но они не сдались и создали проект, который ценят знатоки вина.
			Основательница винотек и баров «Первый нос» Наталья Духина и операционный
			директор сети Надежда Станкевичуте о том, как из небольшой кофейни
			построить успешный бизнес и собрать команду, в которой каждый любит общее
			дело как своё.
		</p>
	),
	video: {
		src: "https://www.youtube.com/embed/o8iLPHdVcu4",
		textarea: (
			<p className={classNames(styles.textarea, styles.wrap)}>
				Нас называли дилетантами <wbr /> и пророчили провал
			</p>
		),
	},
}
