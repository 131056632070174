import React, { useEffect, useState } from "react"
import classNames from "classnames"
import debounce from "lodash.debounce"

import { requestForAutoOpenRestFaster } from "../../apiRequests/callToAction"
import DefaultLayout from "../../layout/default"

import HeadingTextareaAndPrompt from "../../components/_V3/SecretPages/HeadingTextareaAndPrompt"
import MainBanner from "../../components/_V3/SecretPages/MainBanner"

import SocialNetworkBanner from "../../components/_V3/SecretPages/SocialNetworkBanner"

import ConsultationForm from "../../components/_V3/SecretPages/ConsultationForm"

import FooterCompact from "../../components/_V2/FooterCompact"

import SendPulseModal from "../../components/_V2/SendPulseModal"

import { mainBanner } from "../../pages-data/_V3/secret/pervyj-nos/MainBanner/data"
import { headingTextareaAndPrompt } from "../../pages-data/_V3/secret/pervyj-nos/HeadingTextareaAndPrompt/data"
import { socialNetworkBanner } from "../../pages-data/_V3/secret/SocialNetworkBanner/data"
import { consultationForm } from "../../components/_V3/SecretPages/ConsultationForm/data"

import MediaMainBanner from "../../pages-data/_V3/secret/pervyj-nos/MainBanner/assets/MediaMainBanner.webp"

import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/secret.module.scss"

export default function PervyjNos() {
	const title =
		"Секретный ингредиент винотеки «Первый нос»: как из небольшой кофейни вырасти в сеть винотек и баров"
	const description =
		"Героинь нашей истории называли дилетантами — профессионалы пророчили им провал. Но они не сдались и создали проект, который ценят знатоки вина. Основательница винотек и баров «Первый нос» Наталья Духина и операционный директор сети Надежда Станкевичуте о том, как из небольшой кофейни построить успешный бизнес."
	const url = "https://quickresto.ru/secret/pervyj-nos/"

	const metaTags = [
		{
			name: "description",
			content: description,
		},
		{
			property: "og:url",
			content: url,
		},
		{
			property: "og:title",
			content: title,
		},
		{
			property: "og:description",
			content: description,
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content:
				"https://quickresto.ru/static/MediaMainBanner-68d04a49a1e2cf92a918400680eacf12.webp",
		},
	]

	// Проверка страницы на скролл до 50% для показа попапа о подписке
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	let isHalfScrolled = false
	const handleScroll = () => {
		const pageHeight = document.body.scrollHeight
		const pageHalfHeight = pageHeight / 2

		// Если скролл больше половины страницы то показываем попап
		if (window.scrollY >= pageHalfHeight && isHalfScrolled == false) {
			setIsModalOpen(true)

			isHalfScrolled = true
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", debounce(handleScroll, 500))

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	return (
		<DefaultLayout
			title={title}
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.bg
			)}
			linkCanonical={url}
			isFooterEnable={false}
		>
			<MainBanner
				data={mainBanner}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
				titleClassName={pageStyles.wrap}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[0]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[1]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					styles.sectionMarginTop0
				)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[2]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[3]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[4]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[5]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[6]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[7]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[8]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[9]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[10]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
				columnClassName={pageStyles.column__mb20}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[11]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<SocialNetworkBanner
				data={socialNetworkBanner}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[12]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[13]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[14]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
				columnClassName={pageStyles.column__mb0}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[15]}
				className={classNames(pageStyles.pageSection, pageStyles.marginTop20)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[16]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<ConsultationForm
				data={consultationForm}
				className={classNames(pageStyles.pageSection)}
				request={requestForAutoOpenRestFaster}
			/>

			<FooterCompact className={styles.footer} />

			<SendPulseModal isOpen={isModalOpen} onClose={handleModalStatus} />
		</DefaultLayout>
	)
}
