import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"
import PromptIconSeven from "./assets/PromptIconSeven.png"

import PromptIconEight from "./assets/PromptIconEight.png"
import PromptIconNine from "./assets/PromptIconNine.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"
import MediaContentSeven from "./assets/MediaContentSeven.webp"

import MediaContentOneFancybox from "./assets/MediaContentOneFancybox.webp"
import MediaContentTwoFancybox from "./assets/MediaContentTwoFancybox.webp"
import MediaContentThreeFancybox from "./assets/MediaContentThreeFancybox.webp"
import MediaContentFourFancybox from "./assets/MediaContentFourFancybox.webp"
import MediaContentFiveFancybox from "./assets/MediaContentFiveFancybox.webp"
import MediaContentSixFancybox from "./assets/MediaContentSixFancybox.webp"
import MediaContentSevenFancybox from "./assets/MediaContentSevenFancybox.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "Открытие первого заведения было авантюрой",
		textarea: (
			<>
				<i>Наталья:</i> Первую точку&nbsp;&mdash; кофейню &laquo;Корица&raquo;
				за&nbsp;МКАДом&nbsp;&mdash; мы&nbsp;открыли в&nbsp;2015&nbsp;году,
				и&nbsp;это была авантюра. Один товарищ предложил стать инвесторами:
				в&nbsp;комплексе, где он&nbsp;жил, было место с&nbsp;годовой льготной
				арендой в&nbsp;16&ndash;18 тысяч рублей в&nbsp;месяц. Будучи
				в&nbsp;декрете, я&nbsp;подумала, что у&nbsp;меня очень много свободного
				времени, и&nbsp;согласилась. Мы&nbsp;сделали ремонт, закупили
				оборудование, мебель, декор. Набрали персонал, запаслись продукцией,
				подключили систему учёта, оформили документы.
				<br />
				<br />
				Меня поддержал муж, друзья, но&nbsp;в&nbsp;основном все вокруг
				сомневались. А&nbsp;мне кажется, вера&nbsp;&mdash; это самое важное,
				потому что если веришь и&nbsp;в&nbsp;деталях представляешь&nbsp;то, чего
				хочешь, это обязательно сбудется. Ну&nbsp;а&nbsp;секрет
				успеха&nbsp;&mdash; в&nbsp;трудолюбии и&nbsp;в&nbsp;желании изменить мир
				к&nbsp;лучшему, и&nbsp;этого у&nbsp;меня достаточно. До&nbsp;этого
				я&nbsp;много лет работала на&nbsp;телевидении и&nbsp;занималась
				совершенно другими процессами&nbsp;&mdash; всё, что связано
				с&nbsp;открытием кофейни, было для меня новым. Но&nbsp;если
				я&nbsp;загораюсь какой-то идеей, то&nbsp;обязательно должна
				её&nbsp;реализовать.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img
					className={classNames(styles.icon, styles.icon__one)}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>С друзьями лучше работать только на старте и не за деньги</p>
			</div>
		),
	},
	{
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContent__one,
					styles.mediaContent__mt0
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentOneFancybox}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentOne}
							alt="Открытие первого заведения"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					В первый месяц мы вышли в операционный плюс, через полгода
					перевыполнили план в 3 раза
				</p>
			</div>
		),
		textarea2: (
			<>
				Пассивным инвестором я&nbsp;не&nbsp;стала&nbsp;&mdash; всем управляла
				сама, с&nbsp;чем-то помогали друзья. Например, две мои
				подружки&nbsp;&mdash; одна дизайнер, другая архитектор&nbsp;&mdash;
				создавали дизайн-проекты, взаимодействовали со&nbsp;строителями.
				Но&nbsp;с&nbsp;друзьями лучше работать только на&nbsp;старте
				и&nbsp;не&nbsp;за&nbsp;деньги. Сейчас, когда много сложных процессов,
				мы&nbsp;предпочитаем нанимать профессиональные компании, с&nbsp;которых
				можно требовать работу по&nbsp;нашим стандартам и&nbsp;спрашивать
				результат.
				<br />
				<br />
				Несмотря на&nbsp;отсутствие опыта, в&nbsp;первый&nbsp;же месяц
				мы&nbsp;вышли в&nbsp;операционный плюс, а&nbsp;через полгода
				перевыполнили бизнес-план в&nbsp;три раза. В&nbsp;какой-то момент
				я&nbsp;поняла, что невозможно справляться со&nbsp;всем одной
				и&nbsp;нужно делегировать. На&nbsp;собеседование пришла
				Надежда&nbsp;&mdash; так мы&nbsp;и&nbsp;познакомились, и&nbsp;вот уже
				семь лет она моя правая и&nbsp;левая рука, нога, глаза и&nbsp;уши. Она
				отвечает за&nbsp;все процессы: подбор помещения, стройка, разработка
				концепции, подбор персонала, старт заведения и&nbsp;его дальнейшее
				функционирование.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>
					Главное встретить правильных людей, трудиться и желать изменить мир к
					лучшему
				</p>
			</div>
		),
	},
	{
		heading: "В чём секрет успеха",
		textarea: (
			<>
				<i>Надежда:</i> До&nbsp;&laquo;Корицы&raquo; я&nbsp;работала
				в&nbsp;большом заведении, и&nbsp;когда пришла в&nbsp;маленькую кофейню,
				то&nbsp;думала, что наконец отдохну. Но&nbsp;у&nbsp;нашей Натальи
				амбиции оказались намного шире, чем я&nbsp;думала, и&nbsp;расслабиться
				она мне не&nbsp;дала. Теперь то&nbsp;большое заведение из&nbsp;прошлого
				кажется мне детским садом. Секрет успеха&nbsp;&mdash; встретить
				правильных людей на&nbsp;своём пути. Я&nbsp;встретила Наташу. Она верит
				в&nbsp;меня, в&nbsp;нас, мы&nbsp;верим в&nbsp;неё. Поэтому у&nbsp;нас
				всё так хорошо.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>
					Ни один бизнес-план не совпадает с реальностью после открытия
					заведения
				</p>
			</div>
		),
	},
	{
		heading: "Результаты кофейни вдохновили нас развивать бизнес",
		textarea: (
			<>
				<i>Наталья:</i> Первая винотека &laquo;Первый нос&raquo; была небольшая
				и&nbsp;располагалась в&nbsp;соседнем подъезде от&nbsp;нашей кофейни. При
				подготовке к&nbsp;открытию мы&nbsp;нарисовали бизнес-план&nbsp;&mdash;
				именно нарисовали, потому что по&nbsp;факту ни&nbsp;один бизнес-план
				не&nbsp;совпадает с&nbsp;реальностью после открытия заведения.
				А&nbsp;дальше создавали ассортимент, искали поставщиков, персонал:
				кавистов, специалистов ЕГАИС. Айтишники&nbsp;&mdash; одни
				из&nbsp;ключевых сотрудников винотеки: их&nbsp;работа не&nbsp;видна,
				но&nbsp;очень важна.
			</>
		),
	},
	{
		heading: (
			<>
				Суммы на открытие точек отличаются и зависят
				<br />
				от многих факторов
			</>
		),
		textarea: (
			<>
				<i>Надежда:</i> На&nbsp;открытие самого первого &laquo;Носа&raquo;,
				учитывая, что мы&nbsp;не&nbsp;переделывали ремонт и&nbsp;запускались
				на&nbsp;основе нашего первого проекта, мы&nbsp;потратили 1,5 млн рублей.
				Мы&nbsp;договорились с&nbsp;арендодателем о&nbsp;каких-то невероятных
				условиях: я&nbsp;слёзно обещала, что всё будет хорошо, и&nbsp;нам дали
				отсрочку по&nbsp;платежам на&nbsp;полгода. Тогда для нас это были
				большие деньги&nbsp;&mdash; сейчас мы&nbsp;открываем винотеки
				с&nbsp;закупками порядка 10&ndash;30 млн рублей. Суммы на&nbsp;открытие
				точек отличаются и&nbsp;зависят от&nbsp;многих факторов: арендной платы,
				загрузки, ассортимента, среднего чека. Цифры постоянно
				&laquo;пляшут&raquo;.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>Мы открываем винотеки с закупками до 30 млн рублей</p>
			</div>
		),
	},
	{
		heading: "«Первый нос» — это винный термин",
		textarea: (
			<>
				<i>Наталья:</i> Во&nbsp;время подготовки к&nbsp;открытию винотеки
				мы&nbsp;погружались в&nbsp;тему вина, узнавали разные правила
				и&nbsp;термины. Так родилось название. Хотя есть и&nbsp;романтичная
				версия, которую очень любит мой муж: он&nbsp;считает, что самая
				выдающаяся часть моего тела&nbsp;&mdash; нос, и&nbsp;проводит
				с&nbsp;этим аналогию.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContent__one)}
			>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentTwoFancybox}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentTwo}
							alt="Подготовка к открытию винотеки"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwo)}>
					Чем вино сложнее, тем у него больше стадий раскрытия и больше носов
				</p>
			</div>
		),
		textarea2: (
			<>
				Но&nbsp;на&nbsp;самом деле &laquo;первый нос&raquo;&nbsp;&mdash;
				распространённый термин среди винных специалистов. Это первое
				впечатление от&nbsp;вина: вы&nbsp;открываете бутылку, наливаете вино
				в&nbsp;бокал, оно начинает раскрываться, и&nbsp;появляется первый
				аромат&nbsp;&mdash; первый нос. Дальше по&nbsp;мере взаимодействия вина
				с&nbsp;кислородом открываются следующие ноты&nbsp;&mdash; появляется
				второй нос, третий. Чем вино сложнее, тем у&nbsp;него больше стадий
				раскрытия и&nbsp;больше носов. Для многих гостей&nbsp;&mdash; это
				незнакомый термин и&nbsp;хороший повод завязать разговор, рассказать
				историю названия и&nbsp;предложить вино к&nbsp;покупке.
			</>
		),
	},
	{
		heading: "Первое правило наших кавистов — не снобить",
		textarea: (
			<>
				<i>Надежда:</i> Мало того, что вино&nbsp;&mdash; дело вкуса, это ещё
				дело кошелька. Сами виноделы очень обижаются, когда кто-нибудь называет
				вино за&nbsp;2&nbsp;евро посредственным и&nbsp;бюджетным, а&nbsp;про
				вино за&nbsp;1,5 тысячи евро говорят &laquo;вау&raquo;, ещё
				не&nbsp;попробовав. У&nbsp;меня, например, нет любимого вина.
				Вино&nbsp;&mdash; это повод, вино&nbsp;&mdash; это настроение.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContent__one)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentThreeFancybox}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentThree}
							alt="Продажа вина"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descThree)}>
					У нас нет задачи продать сразу самое дорогое вино
				</p>
			</div>
		),
		textarea2: (
			<>
				При продаже вина мы&nbsp;всегда опираемся на&nbsp;предпочтения
				покупателя и&nbsp;повод, для которого он&nbsp;выбирает бутылку.
				У&nbsp;нас нет задачи показать сразу самое дорогое&nbsp;&mdash; есть три
				ценовые категории, и&nbsp;кависты это сразу проговаривают.
				В&nbsp;диалоге они помогают каждому найти в&nbsp;&laquo;Первом
				носе&raquo; вино на&nbsp;свой вкус и&nbsp;кошелёк. Самое
				важное&nbsp;&mdash; не&nbsp;снобить.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>Сложно найти адекватных и трудолюбивых сотрудников</p>
			</div>
		),
	},
	{
		heading: "Оглядываясь на 7 лет назад, понимаешь, чего достиг",
		textarea: (
			<>
				<i>Наталья:</i> Первое&nbsp;&mdash; мы&nbsp;раскрутили бренд. Сейчас
				у&nbsp;нас 13&nbsp;локаций, скоро будет 14. Результат номер
				два&nbsp;&mdash; постоянный рост оборотных средств. Третье&nbsp;&mdash;
				команда. В&nbsp;первой кофейне было около 10&nbsp;человек, сейчас
				во&nbsp;всей сети больше 100 работают на&nbsp;постоянной основе, плюс
				кого-то мы&nbsp;берём сезонно.
				<br />
				<br />
				Четвёртое&nbsp;&mdash; это оптимизация. Мы&nbsp;научились эффективно
				выстраивать бизнес-процессы. Например, запустили и&nbsp;уже почти четыре
				года развиваем отдельное направление&nbsp;&mdash; собственный импорт.
				Мы&nbsp;закупаем вино не&nbsp;у&nbsp;поставщиков в&nbsp;Москве,
				а&nbsp;у&nbsp;производителей за&nbsp;рубежом. Это позволяет увеличить
				маржинальность и&nbsp;сократить себестоимость продукта. Мы&nbsp;завезли
				уже 60&nbsp;позиций&nbsp;&mdash; это не&nbsp;очень много, но&nbsp;это
				ядро продаж, то&nbsp;есть самые продаваемые вина: совиньон блан,
				рислинг, пино нуар, просекко, кава, креман.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContent__one)}
			>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentFourFancybox}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentFour}
							alt="интерьер винотеки"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFour)}>
					В нашей винотеке есть бутылка вина за миллион рублей
				</p>
			</div>
		),
		textarea2: (
			<>
				Ну&nbsp;и&nbsp;пятое достижение, моё личное,&nbsp;&mdash; это коллекции
				вин, которые я&nbsp;собрала и&nbsp;дома, и&nbsp;в&nbsp;винотеке. Есть
				дорогие бутылки, есть очень дорогие. В&nbsp;одной из&nbsp;винотек стоит
				бутылка за&nbsp;миллион рублей. Подобные бриллианты есть ещё
				на&nbsp;нескольких точках. Спрос на&nbsp;такие вина есть,
				но&nbsp;небольшой: их&nbsp;покупают ценители, люди, которые создают
				погреба. Но&nbsp;я&nbsp;не&nbsp;могу сказать, что очень хочу продать
				ту&nbsp;бутылку за&nbsp;миллион&nbsp;&mdash; мне нравится ощущать себя
				её&nbsp;владелицей. У&nbsp;этого вина хороший потенциал хранения, оно
				дорожает с&nbsp;каждым годом&nbsp;&mdash; главное правильно хранить.
				<br />
				<br />
				<i>Наталья:</i> Самое сложное&nbsp;&mdash; собрать команду. Сложно найти
				адекватных, трудолюбивых, улыбчивых людей, которые будут относиться
				к&nbsp;делу с&nbsp;любовью, как к&nbsp;своему. Но&nbsp;у&nbsp;нас это
				получается, и&nbsp;сотрудники&nbsp;&mdash; одно из&nbsp;наших главных
				достижений. Мне нравится наша команда, нравится проводить совещания,
				устраивать корпоративы. Нравится хвалить ребят, а&nbsp;не&nbsp;брать
				на&nbsp;себя роль карателя. Нравится, когда они улыбаются, когда
				у&nbsp;них получается, когда они соревнуются, изо всех сил пытаются
				сделать план. Мне нравится, когда ребята чувствуют себя уверенно,
				чувствуют себя успешными. Когда они берут ипотеки, покупают машины,
				рожают детей.
			</>
		),
	},
	{
		heading: "«Первый нос» — кладезь кадров",
		textarea: (
			<>
				<i>Надежда:</i> У&nbsp;нас классная команда: профессионалы,
				машины-тигры, жадные к&nbsp;деньгам&nbsp;&mdash; поэтому много работают
				и&nbsp;замотивированы на&nbsp;результат. Когда сотрудники так сильно
				любят свою работу&nbsp;&mdash; это 100%-й успех. И&nbsp;от&nbsp;этого
				все мы&nbsp;счастливы, как на&nbsp;&laquo;Доме&nbsp;2&raquo;.
				<br />
				<br />
				Мой главный талант&nbsp;&mdash; подбирать суперкрутых управленцев.
				Каждый из&nbsp;них в&nbsp;какой-то мере и&nbsp;хозяйственник,
				и&nbsp;юрист, и&nbsp;финансист. Мы&nbsp;вкладываемся в&nbsp;обучение
				сотрудников, отправляем ребят на&nbsp;дегустации и&nbsp;другие
				мероприятия, например, связанные с&nbsp;кофейней. Нас часто приглашают
				к&nbsp;производителям вина&nbsp;&mdash; мы&nbsp;были
				на&nbsp;виноградниках в&nbsp;Италии, во&nbsp;Франции.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>У нас собственный импорт, 14 локаций и больше 100 сотрудников</p>
			</div>
		),
	},
	{
		heading: "Самое простое — вести учёт в системе автоматизации",
		textarea: (
			<>
				<i>Наталья:</i> Если перейти от&nbsp;сложных процессов к&nbsp;простым
				и&nbsp;вспомнить о&nbsp;ещё одном нашем достижении&nbsp;&mdash;
				оптимизации бизнес-процессов, то&nbsp;стоит сказать и&nbsp;про систему
				учёта. Ещё в&nbsp;&laquo;Корице&raquo; мы&nbsp;нашли Quick
				Resto&nbsp;&mdash; семь лет назад я&nbsp;не&nbsp;могла поверить, что
				такое существует. Во-первых, фронт-офис на&nbsp;планшете выглядит
				суперстильно. Плюс интерфейс&nbsp;&mdash; какое-то чудо. Я&nbsp;сама
				заводила первые блюда, мне так нравилось ковыряться в&nbsp;бэк-офисе,
				назначать цены, загружать накладные.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContent__one)}
			>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={MediaContentFiveFancybox}
						className={classNames(styles.media, styles.fancy220)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentFive}
							alt="система автоматизации бара"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFive)}>
					Quick Resto — это гибко, удобно и суперстильно
				</p>
			</div>
		),
		textarea2: (
			<>
				Конечно, сейчас этим занимаются IT-специалисты или менеджеры
				на&nbsp;точках. Если возникают какие-то сложности, обращаются
				в&nbsp;службу поддержки, которая готова ответить 24/7. Глобальных
				проблем обычно не&nbsp;возникает&nbsp;&mdash; были сложности
				с&nbsp;интеграцией ЕГАИС, но&nbsp;потом всё настроили, и&nbsp;сейчас всё
				работает корректно.
			</>
		),
	},
	{
		heading: "С Quick Resto мы могли вести операционку сами",
		textarea: (
			<>
				<i>Надежда:</i> До&nbsp;&laquo;Корицы&raquo; я&nbsp;работала только
				с&nbsp;бухгалтерами-калькуляторами&nbsp;&mdash; всё было сложно:
				серьёзные женщины управлялись с&nbsp;катастрофическим количеством
				каких-то бумажек. Когда мы&nbsp;начали работать с&nbsp;Quick Resto,
				я&nbsp;поняла, что этот процесс несложный, и&nbsp;первое время
				операционку вели мы&nbsp;с&nbsp;Наташей: заводили блюда, товары,
				отслеживали маржинальность, делали еженедельные срезы.
			</>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={MediaContentSixFancybox}
						className={classNames(styles.media, styles.fancy220)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentSix}
							alt="касса quick resto"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSix)}>
					Quick Resto собирает информацию о всех гостях
				</p>
			</div>
		),
	},
	{
		textarea: (
			<>
				Плюс мы&nbsp;сразу завели клиентскую базу. Наша первая кофейня
				до&nbsp;сих пор работает. Располагается она в&nbsp;жилом комплексе,
				и&nbsp;у&nbsp;неё много постоянных гостей. Они приходят утром
				в&nbsp;полудрёме: моргнут два раза&nbsp;&mdash; значит, капучино
				с&nbsp;собой, сядут за&nbsp;стол и&nbsp;моргнут&nbsp;&mdash; значит,
				капучино здесь. С&nbsp;такими гостями очень удобно работать
				в&nbsp;системе, которая собирает о&nbsp;них информацию. А&nbsp;ещё
				мы&nbsp;запустили программу Collect and Get&nbsp;&mdash; &laquo;Собери
				и&nbsp;получи&raquo;. Когда гость покупает пять чашек кофе,
				шестая&nbsp;&mdash; в&nbsp;подарок. И&nbsp;это тоже очень удобно
				реализовывать в&nbsp;системе&nbsp;&mdash; мы&nbsp;завели нулевую чашку
				для списания и&nbsp;можем следить за&nbsp;всем до&nbsp;копейки.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSeven)}>
				<img
					className={classNames(styles.icon, styles.icon__seven)}
					src={PromptIconSeven}
					alt="Иконка 7"
					loading="lazy"
				/>
				<p>Чем больше сделаешь, тем мудрее станешь</p>
			</div>
		),
	},
	{
		heading: "Любой опыт, даже отрицательный, — это хорошо",
		textarea: (
			<>
				Главный вывод, который я&nbsp;сделала за&nbsp;годы работы,&nbsp;&mdash;
				все люди разные, и&nbsp;не&nbsp;все добрые и&nbsp;честные, поэтому нужно
				прислушиваться к&nbsp;своей интуиции и&nbsp;быть внимательнее.
				Контролировать и&nbsp;ещё раз контролировать. Мы&nbsp;сталкивались
				с&nbsp;предательством, и&nbsp;это было большое потрясение для всей
				команды.
				<br />
				<br />
				Были проекты, которые мы&nbsp;закрыли,&nbsp;&mdash; один в&nbsp;области,
				другой на&nbsp;одном из&nbsp;рынков Москвы, ещё один в&nbsp;Бутово.
				Я&nbsp;не&nbsp;считаю их&nbsp;ошибкой&nbsp;&mdash; это опыт. Там просто
				оказалась не&nbsp;наша целевая аудитория. В&nbsp;первый раз
				мы&nbsp;ждали долго. Но&nbsp;если не&nbsp;пошло
				месяц-два-три&nbsp;&mdash; всё, ждать не&nbsp;надо, потому что этот
				накопленный минус будет тянуться. Может казаться: &laquo;Сейчас
				переставлю всё с&nbsp;верхней полки на&nbsp;нижнюю&nbsp;&mdash;
				и&nbsp;полетит&raquo;. Нет, так не&nbsp;работает. Не&nbsp;нужно терять
				деньги, лучше закрываться. Опыт&nbsp;&mdash; это количество попыток: чем
				больше сделаешь, тем мудрее станешь.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconEight)}>
				<img
					className={classNames(styles.icon, styles.icon__eight)}
					src={PromptIconEight}
					alt="Иконка 8"
					loading="lazy"
				/>
				<p>Бизнес без делегирования не масштабировать</p>
			</div>
		),
	},
	{
		heading: "Стыдно не падать — стыдно валяться",
		textarea: (
			<>
				<i> Надежда:</i> У&nbsp;нас есть внутреннее правило: если мы&nbsp;упали,
				мы&nbsp;быстро встаём и&nbsp;бежим дальше&nbsp;&mdash; тогда всё
				получается. Например, последняя наша неудача: мы&nbsp;опирались
				на&nbsp;количество чеков у&nbsp;конкурента, а&nbsp;у&nbsp;него оказалась
				намного ниже ценовая политика и&nbsp;другой сегмент
				аудитории&nbsp;&mdash; не&nbsp;наши гости. Зато теперь мы&nbsp;знаем,
				на&nbsp;кого нам нужно опираться.
				<br />
				<br />
				Есть бизнес-процессы, которые уже придумали до&nbsp;нас, и&nbsp;которые
				позволяют часть ошибок не&nbsp;делать&nbsp;&mdash; просто нужно
				пользоваться этими инструментами. Например есть люди-счётчики: они ходят
				около места, в&nbsp;котором планируется открытие, и&nbsp;считают там
				людей&nbsp;&mdash; так можно понять, какой в&nbsp;будущем заведении
				будет трафик и&nbsp;нужно&nbsp;ли открываться именно здесь.
				<br />
				<br />
				Ещё один инструмент&nbsp;&mdash; социологические опросы. Раньше
				я&nbsp;очень скептически к&nbsp;ним относилась, но&nbsp;Наташа меня
				переубедила. Опросы&nbsp;&mdash; это дорого, 400&ndash;500 тысяч рублей,
				но&nbsp;они того стоят. С&nbsp;помощью опросов мы&nbsp;опровергли
				некоторые наши гипотезы и&nbsp;выяснили много полезного.
				Например&nbsp;то, что людям не&nbsp;так важен бренд&nbsp;&mdash;
				им&nbsp;важен ассортимент, качество, подход. Если мы&nbsp;говорим
				о&nbsp;новых игроках&nbsp;&mdash; нужно понимать, что второго шанса
				произвести первое впечатление не&nbsp;будет. Открытие должно быть
				максимально классным, красивым, богатым.
			</>
		),
	},
	{
		heading: "Со временем я обрела важные для руководителя скиллы",
		textarea: (
			<>
				<i>Наталья:</i> В&nbsp;первую очередь я&nbsp;научилась делегировать. Мне
				кажется, это самый важный навык любого руководителя. Невозможно
				масштабировать бизнес, если всё делать самой. Нужно делегировать,
				а&nbsp;для этого нужно выбрать правильных людей. Правильно делегировать
				правильным людям&nbsp;&mdash; равно нужный результат.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContent__two)}
			>
				<Fancybox>
					<a
						data-fancybox="image7"
						href={MediaContentSevenFancybox}
						className={classNames(styles.media, styles.fancy220)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentSeven}
							alt="интерьер винотеки первый нос"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSeven)}>
					Людям важен ассортимент, качество и подход
				</p>
			</div>
		),
	},
	{
		textarea: (
			<>
				Второй важный скилл&nbsp;&mdash; переговоры с&nbsp;арендодателями. Вот
				где мы&nbsp;набили шишек и&nbsp;благодаря этому начали понимать, где
				можно опустить цену, куда нужно и&nbsp;не&nbsp;нужно идти, что
				мы&nbsp;должны прописать в&nbsp;договоре, какие документы обязательно
				стоит проверить. Третье&nbsp;&mdash; работа с&nbsp;поставщиками, это
				тоже часть успеха. За&nbsp;это по&nbsp;большей части отвечает Надежда,
				но&nbsp;и&nbsp;я&nbsp;понимаю, что поставщики тоже разные
				и&nbsp;по-разному себя ведут: кто-то друг, а&nbsp;кто-то таким кажется.
				Помимо финансовых отношений здесь не&nbsp;менее важны человеческие.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconNine)}>
				<img
					className={classNames(styles.icon, styles.icon__nine)}
					src={PromptIconNine}
					alt="Иконка 9"
					loading="lazy"
				/>
				<p>Упал — поднимайся и иди</p>
			</div>
		),
	},
	{
		heading: "Главное при открытии заведения — не бояться и верить",
		textarea: (
			<>
				Надежда: Недавно читала интервью с&nbsp;Борисом Березовским&nbsp;&mdash;
				оно называлось &laquo;Дуракам везёт&raquo;. Там мысль была в&nbsp;том,
				что дураки мало знают и&nbsp;не&nbsp;боятся рисковать, потому что
				не&nbsp;понимают, какие будут последствия. Я&nbsp;считаю, это классная
				позиция для тех, кто планирует начать ресторанный бизнес.
				<br />
				<br />
				Перед открытием винотеки мы&nbsp;общались с&nbsp;профессионалами,
				и&nbsp;они удивлялись, что у&nbsp;нас не&nbsp;будет звёздных сомелье,
				называли нас дилетантами и&nbsp;пророчили провал. Кстати, привет сейчас
				всем, кто так говорил. Так что не&nbsp;бояться&nbsp;&mdash; это совет
				для начинающих номер раз. А&nbsp;второе&nbsp;&mdash; нужно верить
				в&nbsp;свой проект и&nbsp;очень его любить. Упал&nbsp;&mdash; поднимайся
				и&nbsp;иди. А&nbsp;ещё, если говорить про цифры, нужно как можно чаще
				делать срезы, чтобы было время на&nbsp;манёвр.
				<br />
				<br />
				Ну&nbsp;и&nbsp;наша любимая поговорка: &laquo;Лошадь сдохла&nbsp;&mdash;
				слезь&raquo;. Но&nbsp;мы&nbsp;к&nbsp;этому пришли не&nbsp;сразу. Поэтому
				вряд&nbsp;ли те, кто это прочитают, возьмут и&nbsp;закроют свои
				неудачные проекты&nbsp;&mdash; нет, как и&nbsp;мы, будут реанимировать,
				делать искусственное дыхание, придумывать другие концепции. Если
				в&nbsp;проекте есть динамика&nbsp;&mdash; это другой вопрос.
				Но&nbsp;если нет&nbsp;&mdash; не&nbsp;надо ждать. Лучше эти ресурсы
				направить в&nbsp;другое русло: поменять локацию, концепт.
			</>
		),
	},
]
